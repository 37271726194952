import React, { useEffect } from 'react'

export default function TestRoute({ isLoggedIn, checkLoggedIn }) {
  useEffect(() => {
    if (isLoggedIn === null) {
      checkLoggedIn();
    }
  });

  return (
    <div>TestRoute</div>
  )
}
