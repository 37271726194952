import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';

import Paper from '@mui/material/Paper';
import { Button } from '@mui/material';

export default function NotFound() {
  const [counter, setCounter] = useState(3);
  const navigate = useNavigate();

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (counter > 0) {
        setCounter(counter - 1);
      } else {
        navigate('/');
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, [counter, navigate]);

  return (
    <Paper elevation={3} id="notFoundContainer">
      <h1>404</h1>
      <h2>Page not found!</h2>
      <h3>Redirecting to home page in {counter} seconds...</h3>
      <Button variant="contained" sx={{ fontSize: '1.5rem', margin: '30px', padding: '10px', width: '50%' }} onClick={() => navigate("/")} >Or click here to go Home</Button>
    </Paper>
  )
}
