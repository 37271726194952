import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import InputAdornment from "@mui/material/InputAdornment";

export default function Home({ isLoggedIn, checkLoggedIn, setIntendedURL }) {
  const [searchText, setSearchText] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    if (isLoggedIn === null) {
      checkLoggedIn();
    } else if (isLoggedIn === false) {
      setIntendedURL(window.location.hash.substring(1));
      navigate('/login');
    }
  });

  const searchFromInput = (e) => {
    if (e !== undefined) {
      if (e.keyCode === 13) {
        console.log("ENTER KEY PRESSED");
      }
    } else {
      console.log("ENTER KEY NOT PRESSED");
    }
  }

  const performSearch = async () => {
    if (searchText !== "" && searchText !== undefined && searchText !== null) {
      try {
        // const res = await axios.post('https://www.api.jpch-projects.com/api/users', { firstName, lastName, email, password });

        // if (res.status === 201) {
        //   setForm(true);
        // }

      } catch (error) {

        if (error.response.status === 400) {
          // setEmailMessage('Email already in use');
        }
      }
    }
  };

  return (
    <Paper elevation={3} id="homePageContainer">
      <header id="homePageSearchContainer">
        <TextField 
          sx={{ 
            input: { color: 'white' }, 
            margin: '10px', 
            padding: '10px', 
            width: '35%', 
            '& .MuiInput-underline:before': { borderBottom: '3px solid #636363' },
            '& .MuiInput-underline:after': { borderBottom: '3px solid #1976d2' },
          }}
          id="homePageSearchField"
          placeholder="Search questions..."
          inputProps={{ style: { fontSize: '1.5rem' } }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => searchFromInput()}>
                  <SearchIcon fontSize="large" sx={{ ' path': { color: "#1976d2" } }} />
                </IconButton>
              </InputAdornment>
            )
          }}
          onKeyDown={(e) => searchFromInput(e)}
          variant="standard"
        />
      </header>
      <section id="homePageSearchResultsContainer">

      </section>
    </Paper>
  )
}
