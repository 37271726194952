import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

// Components
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';

// Icons
import Login from '@mui/icons-material/Login';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

// Animations
import { useTransition, animated } from 'react-spring';

export default function SignUpOrLogin({ setIsLoggedIn, intendedURL }) {
  // Form data state
  const [firstName, setFirstName] = useState('');
  const [firstNameValidated, setFirstNameValidated] = useState(true);
  const [lastName, setLastName] = useState('');
  const [lastNameValidated, setLastNameValidated] = useState(true);
  const [email, setEmail] = useState('');
  const [emailMessage, setEmailMessage] = useState('');
  const [password, setPassword] = useState('');
  const [passwordMessage, setPasswordMessage] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [confirmPasswordMessage, setConfirmPasswordMessage] = useState('');

  // Form transition state
  const [toggleForm, setForm] = useState(true);
  
  // Form transition animation
  const formTransitions = useTransition(toggleForm, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: { duration: 150 },
  });

  // Allow the MUI Paper component to be animated
  const AnimatedPaper = animated(Paper);

  let navigate = useNavigate();

  // Login user
  const login = async () => {

    if (validateEmail(email) && validatePassword(password)) {
      try {
        const res = await axios.post('https://www.api.jpch-projects.com/api/users/login', { email, password });

        if (res.status === 200) {
          localStorage.setItem('token', res.data.token);
          setIsLoggedIn(true);
          navigate(intendedURL);
        }

      } catch (error) {
        setIsLoggedIn(false);

        if (error.response.status === 404) {
          setEmailMessage('User not found');
        } else if (error.response.status === 401) {
          setEmailMessage('Incorrect email or password');
          setPasswordMessage('Incorrect email or password');
        }
      }
    }
  }

  // Sign up user
  const signUp = async () => {
    if (validateFirstName(firstName) && validateLastName(lastName) && validateEmail(email) && validatePassword(password) && validatePasswordsMatch(password, confirmPassword)) {
      try {
        const res = await axios.post('https://www.api.jpch-projects.com/api/users', { firstName, lastName, email, password });

        if (res.status === 201) {
          setForm(true);
        }

      } catch (error) {

        if (error.response.status === 400) {
          setEmailMessage('Email already in use');
        }
      }
    }
  }

  // Handle form toggling for state
  const handleFormToggle = () => {
    setFirstName("");
    setFirstNameValidated(true);
    setLastName("");
    setLastNameValidated(true);
    setEmail("");
    setPassword("");
    setConfirmPassword("");

    setForm(!toggleForm);
  }

  // Validate first name
  const validateFirstName = (name) => {
    if (name === "" || name === null || name === undefined) {
      setFirstNameValidated(false);
      return false;
    } else {
      setFirstName(name);
      setFirstNameValidated(true);
      return true;
    }
  }
  
  // Validate last name
  const validateLastName = (name) => {
    if (name === "" || name === null || name === undefined) {
      setLastNameValidated(false);
      return false;
    } else {
      setLastName(name);
      setLastNameValidated(true);
      return true;
    }
  }

  // Validate email
  const validateEmail = (email) => {
    if (email === "" || email === null || email === undefined) {
      setEmailMessage("Email is required");
      return false;
    } else {
      let pattern = new RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/);

      if (!pattern.test(email)) {
        setEmailMessage("Please enter a valid email address");
        return false;
      } else {
        setEmailMessage("");
        setEmail(email);
        return true;
      }
    }
  }

  // Validate password
  const validatePassword = (password) => {
    if (password === "" || password === null || password === undefined) {
      setPasswordMessage("Password is required");
      return false;
    } else {
      let pattern = new RegExp(/(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!-\/:-@\[-`{-~])[A-Za-z\d!-\/:-@\[-`{-~]{14,}$/g);

      if (!pattern.test(password)) {
        setPasswordMessage("Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, one number, and one special character");
        return false;
      } else {
        setPasswordMessage("");
        setPassword(password);
        return true;
      }
    }
  }

  // Validate passwords match
  const validatePasswordsMatch = (originalPassword, confirmationPassword) => {
    if (confirmationPassword !== originalPassword) {
      setConfirmPasswordMessage("Passwords do not match");
      setPasswordMessage("Passwords do not match");
      setConfirmPassword("");
      return false;
    } else {
      setPasswordMessage("");
      setConfirmPasswordMessage("");
      setConfirmPassword(confirmationPassword);
      return true;
    }
  }

  return formTransitions((props, item) => item ? (
    <AnimatedPaper elevation={3} id="loginFormContainer" style={props}>
      <div id="loginFormHeader">
        <Login fontSize="large" sx={{ margin: '10px', gridArea: '1 / 1 / 2 / 2', ' path': { color: "#1976d2" } }}/>
        <h1 id="loginFormHeaderText">Log in</h1>
      </div>
      <div>
        <p>Don't have an account? <Button sx={{ color: '#1976d2', fontWeight: 'bold' }} onClick={() => handleFormToggle()}>Register</Button></p>
        <form id="loginForm">
          <TextField 
            sx={{ input: { color: 'white' }, margin: '10px', padding: '10px', width: '75%', '& .MuiInput-underline:after': { borderBottomColor: '#1976d2' } }} 
            label="Email" 
            variant="standard" 
            type="email"
            placeholder="johndoe@gmail.com"
            autoComplete="chrome-off"
            onChange={(e) => setEmail(e.target.value)}
            helperText={emailMessage}
            FormHelperTextProps={{ style: { color: 'red' } }}
            InputLabelProps={{ style: { color: 'white' } }} />
          <TextField 
            sx={{ input: { color: 'white' }, margin: '10px', padding: '10px', width: '75%', '& .MuiInput-underline:after': { borderBottomColor: '#1976d2' } }} 
            label="Password" 
            variant="standard" 
            type="password"
            autoComplete="chrome-off"
            onChange={(e) => setPassword(e.target.value)}
            helperText={passwordMessage}
            FormHelperTextProps={{ style: { color: 'red' } }}
            InputLabelProps={{ style: { color: 'white' } }} />
          <Button variant="contained" sx={{ margin: '10px', padding: '10px', width: '75%' }} onClick={() => login()}>Log In</Button>
        </form>
      </div>
    </AnimatedPaper>
  ) : (
    <AnimatedPaper elevation={3} id="loginFormContainer" style={props}>
      <div id="loginFormHeader">
        <AddCircleOutlineIcon fontSize="large" sx={{ margin: '10px', gridArea: '1 / 1 / 2 / 2', ' path': { color: "#1976d2" } }}/>
        <h1 id="loginFormHeaderText">Create account</h1>
      </div>
      <div>
        <p>Already have an account? <Button sx={{ color: '#1976d2', fontWeight: 'bold' }} onClick={() => handleFormToggle()}>Sign in</Button></p>
        <form id="loginForm">
          <TextField 
            sx={{ input: { color: 'white' }, margin: '10px', padding: '10px', width: '75%', '& .MuiInput-underline:after': { borderBottomColor: '#1976d2' } }} 
            label="First Name" 
            variant="standard"
            type="text"
            placeholder="John"
            autoComplete="chrome-off"
            onChange={(e) => validateFirstName(e.target.value)}
            helperText={firstNameValidated ? "" : "First name is required"}
            FormHelperTextProps={{ style: { color: 'red' } }}
            InputLabelProps={{ style: { color: 'white' } }} />
          <TextField 
            sx={{ input: { color: 'white' }, margin: '10px', padding: '10px', width: '75%', '& .MuiInput-underline:after': { borderBottomColor: '#1976d2' } }} 
            label="Last Name" 
            variant="standard"
            type="text"
            placeholder="Doe"
            autoComplete="chrome-off"
            onChange={(e) => validateLastName(e.target.value)}
            helperText={lastNameValidated ? "" : "Last name is required"}
            FormHelperTextProps={{ style: { color: 'red' } }}
            InputLabelProps={{ style: { color: 'white' } }} />
          <TextField 
            sx={{ input: { color: 'white' }, margin: '10px', padding: '10px', width: '75%', '& .MuiInput-underline:after': { borderBottomColor: '#1976d2' } }} 
            label="Email" 
            variant="standard" 
            type="email"
            placeholder="johndoe@gmail.com"
            autoComplete="chrome-off"
            onChange={(e) => validateEmail(e.target.value)}
            helperText={emailMessage}
            FormHelperTextProps={{ style: { color: 'red' } }}
            InputLabelProps={{ style: { color: 'white' } }} />
          <TextField 
            sx={{ input: { color: 'white' }, margin: '10px', padding: '10px', width: '75%', '& .MuiInput-underline:after': { borderBottomColor: '#1976d2' } }} 
            label="Password" 
            variant="standard" 
            type="password"
            autoComplete="chrome-off"
            onChange={(e) => validatePassword(e.target.value)}
            helperText={passwordMessage}
            FormHelperTextProps={{ style: { color: 'red' } }}
            InputLabelProps={{ style: { color: 'white' } }} />
          <TextField 
            sx={{ input: { color: 'white' }, margin: '10px', padding: '10px', width: '75%', '& .MuiInput-underline:after': { borderBottomColor: '#1976d2' } }} 
            label="Confirm Password" 
            variant="standard" 
            type="password"
            autoComplete="chrome-off"
            onChange={(e) => validatePasswordsMatch(password, e.target.value)}
            helperText={confirmPasswordMessage}
            FormHelperTextProps={{ style: { color: 'red' } }}
            InputLabelProps={{ style: { color: 'white' } }} />
          <Button variant="contained" sx={{ margin: '10px', padding: '10px', width: '75%' }} onClick={() => signUp()}>Sign Up</Button>
        </form>
      </div>
    </AnimatedPaper>
  ));
}