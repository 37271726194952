import React, { useState } from 'react'
import { Routes, Route, useNavigate } from 'react-router-dom';
import axios from 'axios';
import '@fontsource/roboto/300.css';
import './css/App.css';
import Home from './components/Home';
import SignUpOrLogin from './components/SignUpOrLogin';
import NotFound from './components/NotFound';
import TestRoute from './components/TestRoute';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(null);
  const [intendedURL, setIntendedURL] = useState('');

  const navigate = useNavigate();

  const checkLoggedIn = async () => {
    try {
      const res = await axios.get('https://www.api.jpch-projects.com/api/users/me', { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });

      if (res.status === 200) {
        setIsLoggedIn(true);
      }

    } catch (error) { 
      setIsLoggedIn(false);
      if(window.location.hash.substring(1) === "") {
        setIntendedURL("/");
      } else {
        setIntendedURL(window.location.hash.substring(1));
      }
      navigate('/login');
    }
  }

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home isLoggedIn={isLoggedIn} checkLoggedIn={checkLoggedIn} setIntendedURL={setIntendedURL} />} />
        <Route path="/login" element={<SignUpOrLogin setIsLoggedIn={setIsLoggedIn} intendedURL={intendedURL} setIntendedURL={setIntendedURL} checkLoggedIn={checkLoggedIn} />} />
        <Route path="/test" element={<TestRoute isLoggedIn={isLoggedIn} checkLoggedIn={checkLoggedIn} />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
}

export default App;